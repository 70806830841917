
import { Vue } from 'vue-class-component';
import baseRequest from 'axios'
import { ElNotification } from 'element-plus'
import axios from '@/plugins/axios'
import { Prop } from 'vue-property-decorator';
interface RedirectResponse { url: string, status: boolean, message: string }
export default class MiraklButton extends Vue {
	cancelTokenSource = baseRequest.CancelToken.source()
	fullscreenLoading = false
	@Prop(Boolean) isLoginPage!: boolean
	@Prop(Boolean) updateToken!: boolean
	async getToken() {
		this.fullscreenLoading = true
		const payload = {
			companyId: this.$route.query.company_id,
			companyName: this.$route.query.company_name,
			apiKey: this.$route.query.api_key,
			isLoginPage: this.isLoginPage,
			updateToken: this.updateToken,
		}
		const siginInResponse: RedirectResponse = await axios.post('/mirakl/connect', payload, { cancelToken: this.cancelTokenSource.token })
		if (siginInResponse && siginInResponse.url && siginInResponse.status) {
			localStorage.setItem('mirakl-update-token', `${this.updateToken}`)
			window.location.href = siginInResponse.url
		} else {
			ElNotification({
				title: 'Error',
				message: siginInResponse.message,
				type: 'error',
			})
		}
	}
}
